<template>
  <div class="admin-page">
    <div class="container page">
      <v-data-table
        :headers="headers"
        :items="allcampingplaces"
        sort-by="shortname"
        class="table-bordered"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Campingplätze</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog persistent v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  Neuer Campingplatz
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.shortname"
                          label="Kurz *"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.placename"
                          label="Campname"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.city"
                          label="Stadt"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.zipcode"
                          label="PLZ"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          :type="show1 ? 'text' : 'password'"
                          v-model="editedItem.loginpassword"
                          label="Passwort *"
                          required
                          :rules="[rules.required, rules.password]"
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          hint="Min. 8 Zeichen, Verschiedene Zeichen"
                          counter
                          @click:append="show1 = !show1"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  <small>*Pflichtfeld</small>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                    elevation="2"
                  >
                    Abbruch
                  </v-btn>
                  <v-btn color="blue darken-1" text elevation="2" @click="save">
                    Speichern
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Soll der Campingplatz wirklich gelöscht werden?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Abbrechen</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Reset </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import {
  CAMPING_FETCH,
  CAMPING_SAVE,
  CAMPING_CREATE,
} from "@/store/actions.type";

export default {
  name: "admin",
  data: () => ({
    show1: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Kurzbez.",
        align: "start",
        value: "shortname",
      },
      { text: "Campbez.", value: "placename" },
      { text: "Stadt", value: "city" },
      { text: "Aktionen", value: "actions", sortable: false },
    ],
    allcampingplaces: [],
    editedIndex: -1,
    editedItem: {
      shortname: "",
      placename: "",
      city: "",
      street: "",
      zipcode: "",
      loginpassword: "",
    },
    defaultItem: {
      shortname: "",
      placename: "",
      city: "",
      street: "",
      zipcode: "",
      loginpassword: "",
    },
    rules: {
      required: (value) => !!value || "Required.",
      counter: (value) => value.length <= 20 || "Max 20 characters",
      password: (value) => {
        const pattern = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
        return pattern.test(value) || "Bitte sicheres Passwort wählen!";
      },
    },
  }),
 /* computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Neuer Eintrag" : "Eintag editieren";
    },
  },*/
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(CAMPING_FETCH);
      this.allcampingplaces = this.getCampingplaces;
    },
    editItem(item) {
      this.editedIndex = this.allcampingplaces.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.allcampingplaces.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.allcampingplaces.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      let shortname = this.editedItem.shortname;
      let password = this.editedItem.loginpassword;
      let city = this.editedItem.city;
      let zipcode = this.editedItem.zipcode;
      if (this.editedIndex > -1) {
        Object.assign(this.allcampingplaces[this.editedIndex], this.editedItem);
        this.$store.dispatch(CAMPING_SAVE, {
          shortname,
          password,
          city,
          zipcode,
        });
      } else {
        this.$store.dispatch(CAMPING_CREATE, {
          shortname,
          password,
          city,
          zipcode,
        });
        this.allcampingplaces.push(this.editedItem);
      }
      this.close();
    },
  },
  computed: {
    ...mapGetters(["getCampingplaces"]),
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
